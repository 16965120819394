.dizang {
    display: flex;
    flex-direction: Row;
    padding: 30px;
    margin-top: 40px;
    justify-content: center;
    gap: 80px;
    margin-bottom: 80px;
    
}

.nameAndPicture {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.counter {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    background-color:white;
}

.picture {
    width: 300px;
    height: 420px
}

.name {
    font-size: 3em;
    color: #144197;
}

.count-module {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.dynamic-module {
    height: 60px; 
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.dynamic-picture {
    width: 40px;
    height: 50px;
}

.dynamic-name {
    color: #144197;
    font-weight: bold;
}

.result {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.count-num {
    color: #144197;
    font-size: 1.5em;
    margin: 0;
    margin-left: 10px;
    /* font-size: 2em; */
}


.count-button {
    background-color: lightblue; /* 橙色背景 */
    color: white; /* 按鈕文字顏色 */
    font-size: 1.5em; /* 調整字體大小 */
    padding: 15px 30px; /* 調整內邊距，讓按鈕更大 */
    border: none; /* 去掉邊框 */
    border-radius: 10px; /* 圓角設計 */
    cursor: pointer; /* 鼠標懸停變成手型 */
    transition: background-color 0.3s, transform 0.2s; /* 添加過渡效果 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加輕微的陰影 */
  }
  
.count-button:hover {
background-color: #144197; /* 懸停時改變背景顏色 */
transform: scale(1.05); /* 懸停時按鈕稍微放大 */
}

.count-button:active {
background-color: #2d05f5; /* 點擊時進一步變暗 */
transform: scale(0.95); /* 點擊時按鈕稍微縮小 */
}
  

.choose-mode {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.instruction {
    color: gray;
    font-size: 0.8rem;
}

.automatic-mode{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.input-module {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* 針對手機設備 */
@media (max-width: 1024px) {
    .dizang {
        margin-top: 100px;
        flex-direction: column;
        gap: 10px;
    }
   
}
  
  