/* Reset some basic elements */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar container */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  z-index: 1000;
  transition: transform 0.3s ease; /* 添加平滑过渡 */
}

.navbar.hidden {
  transform: translateY(-100%); /* 隐藏navbar */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 100%;
  margin: auto;
}

/* Navbar logo */
.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

/* Navbar menu */
.navbar-menu {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

/* Navbar items */
.navbar-item {
  margin: 0;
}

/* Navbar links */
.navbar-link {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

/* Active link */
.navbar-link.active {
  color: #f0a500;
}

/* Navbar links hover */
.navbar-link:hover {
  color: #f0a500;
}


/* 移动设备样式 */
@media (max-width: 768px) {
  .navbar-menu {
    gap: 0.8rem;
  }
}