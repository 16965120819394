.privacy-policy {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin-top: 100px;
  }
  
 
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom-p {
    color: white;
  }

  /* .title {
    color: white;
  } */