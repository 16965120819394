.dizang-intro {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
    color: #333;
    width: 50%;
    transform: translate(50%, 0);
    overflow-x: hidden; /* 禁止页面水平滚动 */
  }
  
  .intro-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .intro-section {
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 1.8em;
    color: #144197;
    margin-bottom: 10px;
  }
  
  .section-text {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .benefit-list {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .benefit-list li {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  /* 針對手機設備 */
@media (max-width: 1024px) {
    .dizang-intro {
        transform: none;
        width: 100%;
        padding: 20px;
    }
   
}