.description-container {
    background-color: #f7f9fc;  /* 背景色淡雅 */
    padding: 20px;
    border-radius: 10px;  /* 圆角边框 */
    max-width: 600px;  /* 最大宽度限制 */
    margin: 20px auto;  /* 上下分别设置，自动左右居中 */
    margin-bottom: 100px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);  /* 添加阴影 */
    display: flex;
    flex-direction: column;
}

.description-text {
    font-size: 18px;
    color: #2c3e50;
    line-height: 1.6;  /* 提高行高，增加可读性 */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  /* 使用现代字体 */
}

.description-link {
    margin-left: 10px;
    color: #3498db;  /* 使用蓝色链接 */
    font-weight: bold;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;  /* 添加平滑的过渡效果 */
}

.description-link:hover {
    border-bottom: 2px solid #3498db;  /* 悬停时添加下划线 */
    color: #2980b9;  /* 悬停时改变颜色 */
}
