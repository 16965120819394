/* AmitabhaIntro.module.css */
.container {
    background-color: #f5f5f5;
    padding: 20px;
    max-width: 900px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    line-height: 1.8;
}

.title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #4b2c20;
}

.text {
    font-size: 18px;
    margin-bottom: 20px;
    color: #4b2c20;
}

.list {
    padding-left: 20px;
    list-style-type: disc;
    margin-top: 10px;
}

.list li {
    margin-bottom: 10px;
}
