.blogItem-container {
    max-width: 800px; /* 设置最大宽度 */
    margin: 0 auto; /* 自动左右边距实现水平居中 */
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 20px; /* 添加一些内边距 */
    padding-bottom: 100px;
    background-color: #f9f9f9; /* 可选：设置背景颜色 */
    border-radius: 8px; /* 可选：设置圆角 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 可选：设置阴影 */
    line-height: 1.6; /* 可选：增加行高以提高可读性 */
}

.blog-title {
    text-align: center; /* 标题居中对齐 */
    font-size: 2em; /* 可选：设置标题字体大小 */
    margin-bottom: 20px; /* 可选：设置标题下方的间距 */
  }
  
  .blog-content {
    text-align: justify; /* 可选：内容两端对齐 */
  }