.guanyin-container {
    background-color: #f9f9f9;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.guanyin-title {
    font-size: 32px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
}

.guanyin-section {
    margin-bottom: 30px;
}

.guanyin-section h2 {
    font-size: 24px;
    color: #2980b9;
    margin-bottom: 10px;
    border-left: 4px solid #3498db;
    padding-left: 10px;
}

.guanyin-text {
    font-size: 18px;
    color: #2c3e50;
    line-height: 1.6;
}

.guanyin-list {
    list-style-type: disc;
    margin-left: 20px;
    color: #2c3e50;
}

.guanyin-list li {
    font-size: 18px;
    margin-bottom: 8px;
}
