.productList {
    position: relative;
    margin-top: 120px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.google-play {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.web-counter {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* 針對手機設備 */
@media (max-width: 1024px) {
    .productList {
        align-items: start;
        padding-left: 20px;
    }
  
}