.footer {
  background-color: #333; /* 深藍色背景 */
  color: #e0e0e0; /* 柔和的白色 */
  padding: 10px 0; /* 減少 padding 高度 */
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px; /* 減少內邊距 */
  color: #e0e0e0;
}

.footer-section {
  flex: 1;
  margin: 5px; /* 減少 margin */
  min-width: 180px; /* 調整寬度 */
}

.footer-title {
  font-size: 1.2em; /* 調整字體大小 */
  margin-bottom: 5px; /* 減少底部間距 */
}

.footer-section p, .footer-section ul {
  margin: 5px 0; /* 減少段落間距 */
}

.footer-section-link {
  margin: 3px 0; /* 調整間距 */
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section-link:hover {
  color: #ffca28; /* 懸停時變亮 */
}

.footer-bottom {
  border-top: 1px solid #11111f;
  margin-top: 10px; /* 減少頂部 margin */
  padding-top: 5px; /* 減少頂部 padding */
  color: #e0e0e0;
}

.about-content {
  color: #e0e0e0;
}
