/* App.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    position: absolute;
    top: 120px;
    left: 0rem;
    width: 100%;
    height: 100vh;
  }
  
  textarea {
    width: 50vw;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  button {
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:active {
    background-color: #004085;
  }
  
  button.selected {
    background-color: #28a745;
  }
  
  p {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
  }
  

