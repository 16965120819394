.intro-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 36px;
    text-align: center;
    color: #1976d2;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .subtitle {
    font-size: 28px;
    color: #1976d2;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  .list {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .list li {
    margin-bottom: 8px;
    font-size: 18px;
    color: #444;
  }
  