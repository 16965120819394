.support-me {
    width: 100%;
    margin-top: 100px;
    min-height: 100vh;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 垂直居中（可选） */
}

.donation-link {
    color: #0070ba; /* PayPal 蓝色 */
    font-weight: bold;
    text-decoration: none;
    margin: 20px;
    padding-top: 20px;
  }
  
  .donation-link:hover {
    text-decoration: underline;
  }

  /* .picture {
    padding-top: 10px;
    width: 100px;
    height: 100px;
  } */

  .donate {
    text-indent: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .crypto-address {
    word-wrap: break-word; /* 允许单词换行 */
    overflow-wrap: break-word; /* 允许单词换行（兼容性） */
    white-space: normal; /* 允许文本正常换行 */
    font-size: 0.9rem; /* 根据需要调整字体大小 */
    padding: 0.5rem; /* 可选：增加内边距以提升可读性 */
    max-width: 100%; /* 确保最大宽度为100% */
  }

  /* 針對手機設備 */
@media (max-width: 1024px) {
  .support-me {
    margin-top: 120px;
    align-items: start;
  }
 
  .donate {
    text-indent: 0px;
  }
}