.terms-of-service {
    max-width: 800px;
    margin: auto;
    padding: 1rem;
    margin-top: 100px;
  }
  
  .terms-of-service h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .terms-of-service h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .terms-of-service p {
    line-height: 1.6;
  }
  
  .terms-of-service a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-of-service a:hover {
    text-decoration: underline;
  }
  