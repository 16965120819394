/* 背景和文字樣式 */
.zhunti-container {
    background: linear-gradient(135deg, #fbfde3, #bbdefb);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  /* 標題樣式 */
  .zhunti-title {
    font-size: 26px;
    color: #2c3e50;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;
  }
  
  .zhunti-title::after {
    content: '';
    display: block;
    width: 80px;
    height: 3px;
    background-color: #1e88e5;
    margin: 10px auto 0;
    border-radius: 3px;
  }
  
  /* 內容段落樣式 */
  .zhunti-text {
    font-size: 18px;
    color: #34495e;
    line-height: 1.6;
    margin-bottom: 25px;
    padding: 0 20px;
    text-align: justify;
  }
  
  /* 每個section之間的間距 */
  .zhunti-section {
    margin-bottom: 40px;
  }
  
  /* 鼠標懸停效果 */
  .zhunti-section:hover .zhunti-title {
    color: #1e88e5;
  }
  
  .zhunti-section:hover .zhunti-text {
    color: #2c3e50;
    transition: color 0.3s ease-in-out;
  }
  
  /* 手機樣式調整 */
  @media (max-width: 600px) {
    .zhunti-container {
      padding: 15px;
    }
  
    .zhunti-title {
      font-size: 22px;
    }
  
    .zhunti-text {
      font-size: 16px;
    }
  }
  