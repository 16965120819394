.blogListContainer {
    height: 100vh; /* 使容器高度为视口高度 */
    /*text-align: center; /* 使文本居中 */
    margin-top: 150px;
    margin-left: 500px;
}


.no-bullets {
    list-style-type: none;
    padding-left: 0; /* 去掉默认的内边距 */
    display: flex;
    height: auto;
    flex-direction: column;
    gap: 20px;
  }

.styled-link {
    text-decoration: none;
    color: #3498db;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.styled-link:hover {
    background-color: #2980b9;
    color: #ffffff;
}
  

@media (max-width: 768px) {
    .blogListContainer {
      margin-left: 0; /* 手机端不需要margin-left */
    }
}